import React, {useCallback, useMemo} from "react";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {SelectionEmployeeTaskDTO, SelectionTaskResponseDTO, TaskCheckingResultStatusDTO} from "../../../generated/api";
import {Checkbox, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

interface ITaskUserSelectionResponseProps {
    taskResult: TaskCheckingResultStatusDTO
}

const TaskUserSelectionResponse: React.FC<ITaskUserSelectionResponseProps> = (props) => {
    const {taskResult} = props

    const lastResponse = useMemo(() => {
        return taskResult?.lastResponse as SelectionTaskResponseDTO
    }, [taskResult])

    const task = useMemo(() => {
        return taskResult.task as SelectionEmployeeTaskDTO
    }, [taskResult])

    const variants = useMemo(() => {
        return task?.variants || []
    }, [task])

    const selectedVariantIds = useMemo(() => {
        return lastResponse?.selectedVariantIds
    }, [lastResponse])

    const isSelect = useCallback((id: number | undefined) => {
        if (id) {
            return selectedVariantIds?.includes(id)
        }

        return false
    }, [selectedVariantIds])

    const getTextColor = useCallback((id: number | undefined, isCorrect: boolean | undefined) => {
        if (id) {
            if (isCorrect) {
                return 'rgba(64, 187, 69, 1)'
            }

            if (isSelect(id)) {
                return 'rgba(255, 8, 41, 1)'
            }
        }

        return 'rgba(19, 25, 32, 0.38)'
    }, [isSelect])

    return (
        <div>
            {variants.map((item) => {
                return (
                    <Grid container display={'flex'} alignItems={'center'}>
                        <Grid item>
                            <Checkbox disabled defaultChecked={isSelect(item.id)} />
                        </Grid>

                        <Grid item flex={1}>
                            <Grid container display={'flex'} alignItems={'center'}>
                                {isSelect(item.id) && item.isCorrect && (
                                    <Grid item display={'flex'} alignItems={'center'} marginRight={'8px'}>
                                        <CheckIcon sx={{color: 'rgba(64, 187, 69, 1)', width: '24px'}}/>
                                    </Grid>
                                )}

                                {isSelect(item.id) && !item.isCorrect && (
                                    <Grid item display={'flex'} alignItems={'center'} marginRight={'8px'}>
                                        <CloseIcon sx={{color: 'rgba(255, 8, 41, 1)', width: '24px'}}/>
                                    </Grid>
                                )}

                                <Grid item display={'flex'} alignItems={'center'} flex={1}>
                                    <Typography sx={{color: getTextColor(item.id, item.isCorrect)}}>
                                        <div dangerouslySetInnerHTML={{__html: item.content || ''}} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
        </div>
    )
}

export default TaskUserSelectionResponse