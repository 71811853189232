import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    ManualTaskResponseDTO,
    TaskCheckingResultStatusDTO,
    TaskCheckingService,
} from "../../../generated/api";
import {Box, Card, Divider, TextField, Typography} from "@mui/material";
import {getTextPosition} from "../../../helpers/getTextPosition";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import MuiPopover from "@mui/material/Popover";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {setBrs} from "../../../helpers/string";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {isElementInViewport} from "../../../helpers/window/isElementInViewport";
import {scrollToElementCenter} from "../../../helpers/window/scrollToElementCenter";
import {scrollToElementCenterWithinParent} from "../../../helpers/window/scrollToElementCenterWithinParent";

interface ITaskUserManualTextResponseProps {
    taskResult: TaskCheckingResultStatusDTO;
    loadTaskResult?: () => void;
}

// Тип для хранения комментариев
interface Comment {
    id: string;
    text: string;
    endPos: number;
    startPos: number;
    commentAt: string;
}

function wrapTextInRange(
    element: HTMLElement,
    start: number,
    end: number,
    id: string
): void {
    let currentPos = 0;
    let spanCreated = false;

    if (document.getElementsByClassName(`comment_id:${id}`).length > 0) return

    function traverse(node: Node) {
        if (spanCreated) return; // Если span уже создан, выходим

        if (node.nodeType === Node.TEXT_NODE) {
            const nodeLength = node.textContent?.length || 0;

            // Проверка, попадает ли начальная и конечная позиции в этот узел
            if (currentPos + nodeLength > start && currentPos < end) {
                const relativeStart = Math.max(0, start - currentPos);
                const relativeEnd = Math.min(nodeLength, end - currentPos);

                // Разделяем текстовый узел, чтобы выделить нужную область
                const beforeText = (node.textContent || "").slice(0, relativeStart);
                const insideText = (node.textContent || "").slice(relativeStart, relativeEnd);
                const afterText = (node.textContent || "").slice(relativeEnd);

                const items = document.getElementsByClassName(`comment_id:${id}`)
                let order = 1

                if (items && items.length > 0) {
                    order = items.length + 1
                }

                const span = document.createElement("span");
                span.style.cursor = 'pointer'
                span.style.backgroundColor = 'rgba(29, 138, 254, 0.2)'
                span.style.borderBottom = '1px solid rgba(29, 138, 254, 0.5)'
                span.classList.add('comment', `comment_id:${id}`, `order№${order}`, `start_p:${start}`, `end_p:${end}`)
                span.textContent = insideText;

                span.onmouseout = () => {
                    if (items) {
                        Array.from(items).forEach((el) => {
                            const spanItem = el as HTMLSpanElement

                            if (el.className.includes('active')) return

                            spanItem.style.backgroundColor = 'rgba(29, 138, 254, 0.2)'
                            spanItem.style.borderBottom = '1px solid rgba(29, 138, 254, 0.5)'
                            spanItem.style.color = 'inherit'
                        })
                    }
                }

                // Вставляем новый текстовый узел и `span` в DOM
                const parent = node.parentNode!;
                if (beforeText) parent.insertBefore(document.createTextNode(beforeText), node);
                parent.insertBefore(span, node);
                if (afterText) parent.insertBefore(document.createTextNode(afterText), node);

                // Удаляем исходный текстовый узел
                parent.removeChild(node);
                // spanCreated = true;
            }

            currentPos += nodeLength;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (const child of Array.from(node.childNodes)) {
                traverse(child);
                if (spanCreated) break;
            }
        }
    }

    traverse(element);

    const selection = document.getSelection()

    if (selection) {
        selection.removeAllRanges()
    }
}

const getMostShortElement = function (element: HTMLElement, blockId: string | undefined) {
    let currentElement = element
    const tree = []

    while (currentElement.id !== blockId) {
        let startPosition = null as number | null
        let endPosition = null as number | null

        currentElement.classList.forEach((classStr) => {
            if (classStr.includes('start_p:')) {
                startPosition = classStr.split(':')[1] ? Number(classStr.split(':')[1]) : null
            }

            if (classStr.includes('end_p:')) {
                endPosition = classStr.split(':')[1] ? Number(classStr.split(':')[1]) : null
            }
        })

        tree.push({
            element: currentElement,
            startPosition,
            endPosition
        })

        if (currentElement.parentElement) {
            currentElement = currentElement.parentElement
        }
    }

    const mostShortElement = tree.reduce((selectedItem, item) => {
        if (selectedItem) {
            if (typeof item.endPosition === 'number' && typeof selectedItem.endPosition === 'number') {
                if (selectedItem.endPosition > item.endPosition) {
                    return item
                }
            }

            if (typeof item.startPosition === 'number' && typeof selectedItem.startPosition === 'number') {
                if (selectedItem.startPosition < item.startPosition) {
                    return item
                }
            }
        } else {
            return item
        }

        return selectedItem
    }, null as {
        element: HTMLElement,
        startPosition: number | null,
        endPosition: number | null
    } | null)

    if (mostShortElement && mostShortElement.element) {
        currentElement = mostShortElement.element
    }

    return currentElement
}

const getCommentIdInClassList = function (element: HTMLElement) {
    let commentId = null as string | null

    element.classList.forEach((classStr) => {
        if (classStr.includes('comment_id:')) {
            commentId = classStr.split(':')[1] ? classStr.split(':')[1] : null
        }
    })

    return commentId
}

const Popover = styled(MuiPopover)({
    ['.MuiPopover-paper']: {
        width: '400px',
        paddingBottom: '8px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        overflow: 'visible',

    }
})

const TaskUserManualTextResponse: React.FC<ITaskUserManualTextResponseProps> = (props) => {
    const { taskResult, loadTaskResult } = props
    const lastResponse = useMemo(() => {
        return taskResult?.lastResponse as ManualTaskResponseDTO
    }, [taskResult])
    const textComments = useMemo(() => {
        if (lastResponse.textComments) {
            return lastResponse.textComments.sort((a, b) => {
                return (a.startPos || 0) - (b.startPos || 0)
            })
        }

        return []
    }, [lastResponse])
    // @ts-ignore
    const [comments, setComments] = useState<Array<Comment>>(textComments ? textComments : [])
    const [indexComment, setIndexComment] = useState(0)
    const [activeComment, setActiveItem] =  useState<Comment | null>(null)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [saveLoading, setSaveLoading] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)
    const open = Boolean(anchorEl);
    const response = useMemo(() => {
        return lastResponse?.text
    }, [lastResponse])
    const textBlockId = useMemo(() => {
        if (taskResult.id) {
            return `text__block:${taskResult.id}`
        }

        return ''
    }, [taskResult])
    const storage = localStorage.getItem('boarding_manual_text')

    const hasIntro = useMemo(() => {
       return !!storage
    },[storage])

    const [intro, setIntro] = useState(!hasIntro)

    const handleChange = useCallback((e: Event) => {
        const target = e.target as HTMLInputElement

        setActiveItem(prevState => {
            if (prevState) {
                return {
                    ...prevState,
                    text: target.value
                }
            }

            return prevState
        })
    }, [])

    const handleMouseUp = useCallback((e: MouseEvent) => {
        const target = e.currentTarget as HTMLElement
        const selection = window.getSelection()

        if (selection) {
            const range = selection.getRangeAt(0)
            const selectedText = range.toString()
            const startOffset = getTextPosition(target, range)
            const endOffset = selectedText.length + startOffset

            if (startOffset !== endOffset) {
                const newComment = {
                    id: String(Date.now()),
                    text: '',
                    endPos: endOffset,
                    startPos: startOffset,
                    commentAt: new Date().toString()
                }

                setComments(prevState => [...prevState, newComment])
                setActiveItem(newComment)
            }
        }
    }, [])

    const handleMouseOver = useCallback((e: MouseEvent) => {
        let target = e.target as HTMLElement
        const currentTarget = e.currentTarget as HTMLElement

        if (target) {
            target = getMostShortElement(target, textBlockId)
        }

        if (target.className.includes('comment')) {
            target.classList.forEach((classStr) => {
                if (classStr.includes('comment_id:')) {
                    const commentId = classStr.split(':')[1]

                    if (commentId) {
                        const items = currentTarget.getElementsByClassName(`comment_id:${commentId}`)

                        Array.from(items).forEach((el) => {
                            const spanItem = el as HTMLSpanElement

                            spanItem.style.backgroundColor = 'rgba(29, 138, 254, 1)'
                            spanItem.style.borderBottom = '1px solid rgba(29, 138, 254, 1)'
                            spanItem.style.color = 'white'
                        })
                    }
                }
            })
        }
    }, [])

    const onClick = useCallback((e: MouseEvent) => {
        let target = e.target as HTMLElement

        if (target && target.id !== textBlockId) {
            target = getMostShortElement(target, textBlockId)

            setAnchorEl(target);

            const commentId = getCommentIdInClassList(target)

            const comment = comments.find((el) => el.id === commentId)

            const index = comments.findIndex((el) => el.id === commentId)

            if (index !== -1) {
                setIndexComment(index)
            }

            setActiveItem(comment || null)
        }
    }, [comments, textBlockId])

    const onRemove = useCallback(async () => {
        setRemoveLoading(true)

        if (activeComment && textComments?.some((el) => el.id === activeComment?.id)) {
            if (taskResult.id) {
                await TaskCheckingService.deleteAppApiEducationtaskTaskcheckingRemovemanualresponsecomment({
                    id: String(taskResult.id),
                    commentId: activeComment?.id
                })

                if (loadTaskResult) {
                    await loadTaskResult()
                }
            }
        }


        if (activeComment) {
            setComments((prevState => {
                return prevState.filter((el) => el.id !== activeComment.id)
            }))

            setActiveItem(null)
        } else if (!activeComment) {
            setComments((prevState => {
                return prevState.filter((el) => !!el.text)
            }))
        }

        setRemoveLoading(false)

        setAnchorEl(null)
    }, [activeComment])

    const onSaveComment = useCallback(async () => {
        if (taskResult?.id && activeComment) {
            setSaveLoading(true)

            if (textComments?.some(el => el.id === activeComment.id)) {
                await TaskCheckingService.patchAppApiEducationtaskTaskcheckingChangemanualresponsecomment({
                    id: String(taskResult.id),
                    commentId: activeComment.id,
                    requestBody: {
                        text: activeComment.text,
                    },
                })
            } else {
                await TaskCheckingService.postAppApiEducationtaskTaskcheckingAddtextmanualresponsecomment({
                    id: String(taskResult.id),
                    requestBody: {
                        text: activeComment.text,
                        startPos: activeComment.startPos,
                        endPos: activeComment.endPos
                    },
                })
            }

            if (loadTaskResult) {
                await loadTaskResult()
            }

            setSaveLoading(false)

            handleClose()
        }
    }, [taskResult, activeComment])

    const onNextComment = useCallback(() => {
        if (comments.length > 0) {
            setIndexComment(prevState => {
                if (prevState >= comments.length - 1) {
                    setActiveItem(comments[0])

                    return 0
                }

                const index = ++prevState

                setActiveItem(comments[index])

                return index
            })
        }
    }, [comments])

    const onPrevComment = useCallback(() => {
        if (comments.length > 0) {
            setIndexComment(prevState => {
                if (prevState === 0) {
                    const i = comments.length - 1

                    setActiveItem(comments[i])

                    return i
                }

                const i = --prevState

                setActiveItem(comments[i])

                return i
            })
        }
    }, [comments])

    const handleClose = () => {
        setAnchorEl(null);

        if (!activeComment?.text || textComments.every((el) => el.id !== activeComment.id)) {
            onRemove()
        }

        setActiveItem(null)
    };

    useEffect(() => {
        if (textComments) {
            // @ts-ignore
            setComments(textComments)
        }
    }, [textComments])

    useEffect(() => {
        const block = document.getElementById(textBlockId)

        if (block) {
            block.innerText = response ? response : ''

            comments.forEach((comment) => {
                wrapTextInRange(block, comment.startPos, comment.endPos, comment.id)
            })
        }
    }, [comments, textBlockId])

    useEffect(() => {
        if (activeComment) {
            const elements = document.getElementsByClassName(`comment_id:${activeComment.id}`)

            if (elements && elements.length > 0) {
                Array.from(elements).forEach((el) => {
                    if (el && el.className.includes('order№1')) {

                        const block = document.querySelector('.checking_result_tasks_list')

                        if (block && !isElementInViewport(el as any)) {
                            setAnchorEl(null)

                            scrollToElementCenterWithinParent(el as any, block as any)

                            setTimeout(() => {
                                setAnchorEl(el as HTMLElement)
                            }, 1000)
                        } else {
                            setAnchorEl(null)

                            setTimeout(() => {
                                setAnchorEl(el as HTMLElement)
                            }, 200)
                        }
                    }
                })
            }
        }
    }, [activeComment?.id])

    useEffect(() => {
        const elements = document.getElementsByClassName(`comment`)

        if (elements) {
            Array.from(elements).forEach((el) => {
                const span = el as HTMLSpanElement

                if (activeComment && span.className.includes(`comment_id:${activeComment.id}`)) {
                    span.style.backgroundColor = 'rgba(29, 138, 254, 1)'
                    span.style.borderBottom = '1px solid rgba(29, 138, 254, 1)'
                    span.style.color = 'white'

                    span.classList.add('active')
                } else {
                    span.style.backgroundColor = 'rgba(29, 138, 254, 0.2)'
                    span.style.borderBottom = '1px solid rgba(29, 138, 254, 0.5)'
                    span.style.color = 'inherit'

                    span.classList.remove('active')
                }
            })
        }
    }, [activeComment])

    if (!response) return null

    return (
        <div>
            <div style={{position: 'relative'}}>
                <Typography
                    style={{
                        filter: intro ? 'blur(12px)' : undefined,
                        pointerEvents: intro ? 'none' : undefined
                }}>
                    <div
                        onClick={onClick as any}
                        onMouseOver={handleMouseOver as any}
                        id={textBlockId}
                        onMouseUp={handleMouseUp as any}
                    >
                        {response ? response : ''}
                    </div>
                </Typography>

                {intro && (
                    <Card
                        style={{
                            width: '448px',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '12px'
                        }}>
                        <Box padding={'12px'}>
                            Вы можете комментировать отдельные части текста текстового ответа.
                            Для этого выделите нужный фрагмент, зажав левую кнопку мыши.
                        </Box>

                        <Divider />

                        <Grid padding={'12px'} justifyContent={'flex-end'} container>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        localStorage.setItem('boarding_manual_text', 'true')

                                        setIntro(false)
                                    }}
                                    size={'small'}
                                    variant={'contained'}
                                    color={'primary'}
                                >
                                    Понятно
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </div>

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Paper style={{
                    borderRadius: '12px 12px 12px 0',
                }}>
                    <Grid container padding={'10px'} justifyContent={'space-between'}>
                        {comments.length > 1 && textComments && textComments.some((el) => el.id === activeComment?.id) && (
                            <Grid item width={'62px'} display={'flex'} justifyContent={'space-between'}>
                                <IconButton
                                    aria-label="prev"
                                    size="small"
                                    disabled={indexComment === 0}
                                    onClick={onPrevComment}
                                >
                                    <KeyboardArrowLeftIcon fontSize="inherit"/>
                                </IconButton>

                                <IconButton
                                    aria-label="next"
                                    size="small"
                                    disabled={indexComment === comments.length - 1}
                                    onClick={onNextComment}
                                >
                                    <KeyboardArrowRightIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        )}

                        <Grid item />

                        <Grid item>
                            <IconButton aria-label="next" size="small" onClick={handleClose}>
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container padding={'12px'}>
                        <Grid item width={'100%'}>
                            <TextField
                                value={activeComment?.text || ''}
                                onChange={handleChange as any}
                                label={'Комментарий'}
                                size={'small'}
                                fullWidth
                                multiline
                                maxRows={4}
                            />
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container padding={'12px'} justifyContent={'flex-end'}>
                        <Grid item marginRight={'8px'}>
                            <LoadingButton
                                loading={removeLoading}
                                onClick={() => {
                                    onRemove()
                                }}
                                size={'small'}
                                color={'error'}
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    color: 'rgba(255, 8, 41, 1)'
                                }}
                            >
                                Удалить
                            </LoadingButton>
                        </Grid>

                        <Grid item>
                            <LoadingButton
                                disabled={!activeComment?.text}
                                loading={saveLoading}
                                onClick={onSaveComment}
                                color={'primary'}
                                variant="contained"
                                size={'small'}
                                style={{
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    backgroundColor: !activeComment?.text ? 'rgba(0,0,0,0.2)' : 'rgba(29, 138, 254, 1)'
                                }}
                            >
                                Сохранить
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Popover>
        </div>
    )
}

export default TaskUserManualTextResponse