/* Функция для получения позиции начала выделения текста отностильно контейнера - block */
export function getTextPosition(block: HTMLElement, range: Range): number {
    let offset = 0;
    let found = false;

    function traverse(node: Node) {
        if (found) return;

        if (node.nodeType === Node.TEXT_NODE) {
            if (node === range.startContainer) {
                offset += range.startOffset;
                found = true;
            } else {
                offset += (node.textContent || "").length;
            }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            for (const child of node.childNodes) {
                traverse(child);
                if (found) break;
            }
        }
    }

    traverse(block);
    return offset;
}
