import {CircularProgress, Grid, Stack, Typography} from "@mui/material";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {
    TaskCheckingResultStatusDTO,
    TaskCheckingService,
    TaskResultEnum
} from "../../../generated/api";
import {useNavigate, useParams} from "react-router-dom";
import TaskChecking from "../../custom/TaskChecking";
import TestCheckingResultTasksListHeader from "./components/TestCheckingResultTasksListHeader";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {LoadingButton} from "@mui/lab";

export default function TaskCheckingResultTasksList() {
    const resultId = parseInt(useParams()?.resultId || '');
    const navigate = useNavigate();
    const [editedTaskResult, setEditedTaskResult] = React.useState<TaskCheckingResultStatusDTO|null>(null);
    const [isLoading, setIsLoading] = React.useState(false)
    const [loadingFinish, setLoadingFinish] = React.useState<boolean>(false)
    const [saved, setSaved] = useState(false)

    const refresh = useCallback(async () => {
        setIsLoading(true)

        let taskResultForEdit = await TaskCheckingService.getAppApiEducationtaskTaskcheckingGetbyid({
            id: resultId
        });

        setIsLoading(false)
        setEditedTaskResult(taskResultForEdit)
    }, [setEditedTaskResult, resultId]);

    const task = useMemo(() => {
        return editedTaskResult?.task
    }, [editedTaskResult])

    useEffect(() => {
        refresh();
    }, [resultId]);

    const onCheckingComplete = useCallback(() => {
        refresh()

        setSaved(true)
    }, [refresh])

    const taskName = useMemo(() => {
        if (task && task.examStructTasks && task.examStructTasks[0]) {
            const examStructTask = task.examStructTasks[0]

            if (examStructTask) {
                return `№${examStructTask.number} ${examStructTask.examType?.name || ''}`
            }
        }

        return ''
    }, [task])

    const renderStatus = useMemo(() => {
        if (editedTaskResult?.result === TaskResultEnum.SKIPPED) {
            return <CheckCircleIcon sx={{color: 'rgba(19, 25, 32, 0.38)', width: '24px'}} />;
        }

        if (!editedTaskResult?.result) {
            return <WarningIcon sx={{ color: 'rgba(255, 8, 41, 1)', width: '24px' }} />;
        }

        return <CheckCircleIcon sx={{color: 'rgba(64, 187, 69, 1)', width: '24px'}} />;
    }, [editedTaskResult])

    const StatusElement = useMemo(() => {
        if (typeof editedTaskResult?.score === 'number') {
            return (
                <div>
                    {editedTaskResult.score}
                    <span style={{color: 'rgba(19, 25, 32, 0.38)'}}> из </span>
                    {editedTaskResult.task?.score}
                </div>
            )
        } else {
            return <div>Не проверено</div>
        }
    }, [task])

    const onFinishChecking = useCallback(async () => {
        setLoadingFinish(true)

        TaskCheckingService.getAppApiEducationtaskTaskcheckingGetlistforchecking({
            limit: 1,
            isChecked: 0 as any,
        })
            .then((response) => {
                if (response.items && response.items[0]) {
                    const item = response.items[0]

                    navigate(`/checking/tasks/${item.id}`)
                } else {
                    navigate(`/checking/tasks`);
                }
            }).catch(() => {
            navigate(`/checking/tasks`)
        }).finally(() => {
            setLoadingFinish(false)
        })
    }, [navigate, setLoadingFinish])

    const isDone = useMemo(() => {
        if (editedTaskResult) {
            return typeof editedTaskResult.score === 'number'
        }

        return false
    }, [editedTaskResult])

    useEffect(() => {
        if (isDone && saved) {
            const block = document.querySelector('.checking_result_tasks_list')

            if (block) {
                setTimeout(() => {
                    block.scrollTo({
                        top: block.scrollHeight,
                        behavior: 'smooth'
                    })
                }, 300)
            }

            setSaved(false)
        }
    }, [isDone, saved])

    return (
        <div style={{overflow: 'auto', height: '100%'}} className={'checking_result_tasks_list'}>
            {!editedTaskResult && isLoading && (
                <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress />
                </div>
            )}

            <Grid>
                {editedTaskResult && task && (
                    <>
                        <TestCheckingResultTasksListHeader
                            testName={taskName}
                            userName={editedTaskResult.user?.name}
                        />

                        <Stack bgcolor={'white'} borderRadius={'12px'} overflow={'hidden'}>
                            <div style={{
                                padding: '16px 24px',
                                height: '72px',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid rgba(19, 25, 32, 0.08)',
                            }}>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid item>
                                        <Grid container alignItems={'center'}>
                                            <Grid item marginRight={'8px'} display={'flex'} alignItems={'center'}>
                                                {renderStatus}
                                            </Grid>

                                            <Grid item>
                                                <Typography fontSize={'20px'} lineHeight={'32px'} fontWeight={500}>
                                                    {task.id}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Typography color={'rgba(19, 25, 32, 0.87)'} fontSize={'20px'} lineHeight={'32px'} fontWeight={400}>
                                            {StatusElement}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>

                            <TaskChecking
                                taskResult={editedTaskResult}
                                task={task}
                                onCheckComplete={onCheckingComplete}
                                loadTaskResult={onCheckingComplete}
                            />
                        </Stack>
                    </>
                )}

                {isDone && (
                    <Grid padding={'16px 0 0 0'}>
                        <LoadingButton
                            loading={loadingFinish}
                            onClick={onFinishChecking}
                            variant={'outlined'}
                            fullWidth
                        >
                            работа проверена, к следующей
                        </LoadingButton>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
