import {ApiError} from "../generated/api";

export default function defaultErrorHandle(err: ApiError) {
    if (err?.body?.errors !== null && err?.body?.errors) {
        const errValue = Object.values(err.body.errors)[0]

        if (Array.isArray(errValue)) {
            alert('Ошибка: ' + errValue.join("\n"))
        }
    }
}
