import {Editor} from "@tinymce/tinymce-react";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef} from "react";
import getToken from "../../helpers/getToken";
import {Editor as TinyMCEEditor} from "tinymce";
import FileUploaderDialog from "../custom/FileUploaderDialog";
import {FileInfoDTO} from "../../generated/api";
import './tiny-mce.css'

export default function TinyMceEditorWrapper({initialValue, height, onChange, onInit, minimal = false, autoSaveInterval = null}:
                                                 {
                                                     height: string | number
                                                     initialValue?: string | null,
                                                     onChange: (value: string) => void,
                                                     onInit?: (e: any, editor: TinyMCEEditor) => void,
                                                     minimal?: boolean,
                                                     autoSaveInterval?: number | null
                                                 }) {
    const [isUploadDialogOpen, setIsUploadDialogOpen] = React.useState(false)
    const [onUpload, setOnUpload] = React.useState<(file: FileInfoDTO) => void>(() => () => {
    })
    const onUploadDialogClose = useCallback(() => {
        setIsUploadDialogOpen(false)
    }, [setIsUploadDialogOpen])
    const onUploadDialogInsert = useCallback((file: FileInfoDTO) => {
        setIsUploadDialogOpen(false)
        onUpload(file)
    }, [setIsUploadDialogOpen, onUpload])

    const editorInstance = useRef<TinyMCEEditor>()

    const firstInitialValue = useMemo(() => initialValue || '', [])
    const uploadHandler = useCallback((blobInfo: any, progress: any) => {
        let xhr: XMLHttpRequest, formData: FormData;
        return new Promise<string>((resolve, reject) => {
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', process.env.REACT_APP_API_URL + '/tiny/upload/image');
            xhr.setRequestHeader('Authorization', 'Bearer ' + getToken());
            xhr.upload.onprogress = function (e) {
                progress(e.loaded / e.total * 100);
            };

            xhr.onload = function () {
                var json;

                if (xhr.status === 403) {
                    reject('HTTP Error: ' + xhr.status);
                    return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                    reject('HTTP Error: ' + xhr.status);
                    return;
                }

                json = JSON.parse(xhr.responseText);

                if (!json || typeof json.location != 'string') {
                    reject('Invalid JSON: ' + xhr.responseText);
                    return;
                }

                resolve(json.location);
            };

            xhr.onerror = function () {
                reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
            };

            formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());
            xhr.send(formData);
        })
    }, [])
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const options = {
        height: height,
        menubar: false,
        statusbar: !minimal,
        setup: function (editor: any) {
            editorInstance.current = editor
            editor.ui.registry.addButton('customUploadButton', {
                icon: 'embed',
                onAction: (_: any) => {
                    setIsUploadDialogOpen(true)
                    setOnUpload(() => (file: FileInfoDTO) => {
                        if (file.mimeType?.startsWith('audio/')) {
                            editor.insertContent(`<audio controls>
                    <source src="${file.url}" type="${file.mimeType}">
                    Ваш браузер не поддерживает воспроизведение аудио
                  </audio>`)
                        } else {
                            editor.insertContent(`<a href="${file.url}" target="_blank">${file.fileName}<a>`)
                        }
                    })
                }
            });
        },
        // skin: prefersDarkMode ? 'oxide-dark' : 'oxide',
        // content_css: prefersDarkMode ? 'dark' : 'default',
        image_uploadtab: true,
        // images_upload_base_path: '/some/basepath',
        file_picker_types: 'image',
        images_upload_handler: uploadHandler,
        draggable_modal: true,
        extended_valid_elements: '*[.*]',
        mathTypeParameters: {
            serviceProviderProperties: {
                URI: 'https://wiris.ucheba.ru/integration',
                server: 'php'
            }
        },
        automatic_uploads: false,
        external_plugins: {
            'tiny_mce_wiris': `https://wiris.ucheba.ru/plugin.min.js`,
        },
        plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'media', 'table', 'preview', 'help', 'wordcount', 'codesample', 'code'
        ]
    }
    if (minimal) {
        options['plugins'].push('quickbars')
        Object.assign(options, {
            toolbar: false,
            toolbar_sticky: true,
            quickbars_insert_toolbar: 'tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry quicktable codesample customUploadButton',
            quickbars_selection_toolbar: 'bold italic underline strikethrough | superscript subscript | blocks | blockquote quicklink',
            contextmenu: 'undo redo | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry codesample | inserttable | cell row column deletetable | customUploadButton | help',
        })
    } else {
        Object.assign(options, {
            toolbar: 'undo redo | blocks | ' +
                'bold italic underline strikethrough | superscript subscript | forecolor backcolor removeformat | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry codesample | table | image customUploadButton | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'help | code | fullscreen'
        })
    }
    const changeHandler = useCallback((e: any, editor: TinyMCEEditor) => {
        onChange(editor.getContent())
    }, [onChange])


    useEffect(() => {
        if (!autoSaveInterval || !editorInstance) {
            return
        }
        const interval = setInterval(() => {
            editorInstance?.current?.save()
        }, autoSaveInterval)

        return () => clearInterval(interval)
    }, [autoSaveInterval, editorInstance])

    return <>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          initialValue={firstInitialValue}
          onChange={changeHandler}
          init={options}
          onInit={onInit}
      />
      { isUploadDialogOpen ? <FileUploaderDialog open={isUploadDialogOpen} onClose={onUploadDialogClose} onInsert={onUploadDialogInsert} /> : null }
    </>
}