import React, {FC} from "react";
import {Avatar, Typography} from "@mui/material";
import {stringAvatar} from "../bll/stringAvatar";

interface ITestCheckingResultTasksListHeaderProps {
    testName?: string
    userName?: string
}

const TestCheckingResultTasksListHeader: FC<ITestCheckingResultTasksListHeaderProps> = (props) => {
    const {testName, userName} = props

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px 0', position: 'relative' }}>
            {testName && (
                <div>
                    <Typography fontSize={'32px'} fontWeight={500} component="h2" whiteSpace={'nowrap'} >
                        {testName}
                    </Typography>
                </div>
            )}

            {userName && (
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '16px', right: 0 }}>
                    <div style={{
                        width: '40px',
                        height: '40px',
                        background: 'linear-gradient(0.25turn, rgba(245, 247, 249, 0), rgba(245, 247, 249, 1))'
                    }}>

                    </div>
                    <div style={{display: 'flex', alignItems: 'center', backgroundColor: 'rgba(245, 247, 249, 1)'}}>
                        <div style={{paddingRight: '12px'}}>
                            <Avatar {...stringAvatar(userName, {
                                width: 40,
                                height: 40,
                                fontSize: '20px',
                                fontWeight: 600
                            })} />
                        </div>

                        <div>
                            <Typography fontSize={'32px'} fontWeight={400} whiteSpace={'nowrap'}>
                                {userName}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TestCheckingResultTasksListHeader