import {createTheme} from '@mui/material'

declare module '@mui/material/styles' {
    interface Palette {
        greyBlue150: {
            main: string
        }
    }

    interface PaletteOptions {
        greyBlue150?: {
            main: string
        }
    }
}

const theme = createTheme({
    palette: {
        mode: 'light',
        secondary: {
            main: '#263340'
        },
        primary: {
            main: '#1D8AFE'
        },
        error: {
            main: '#FF0829'
        },
        success: {
            main:'#40BB45'
        },
        greyBlue150: {
            main: '#E9EDF2'
        }
    },
    components: {
        MuiDivider: {
          styleOverrides: {
              root: {
                  borderColor: 'rgba(19, 25, 32, 0.08)'
              }
          }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
                text: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                sizeSmall: {
                    padding: '8px 22px',
                    fontSize: '15px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    height: '40px',
                },
                sizeMedium: {
                    padding: '12px 24px',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 500,
                    height: '48px',
                },
                sizeLarge: {
                    fontSize: '18px',
                    height: '56px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                  body {
                    background-color: #F5F7F9;
                  }
                `,
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        borderRadius: '8px',
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: ({ownerState}) => ({
                    ...(ownerState.size === 'medium' && {
                        height: '48px',
                        padding: '12px',
                        boxSizing: 'border-box'
                    }),
                }),
                root: {
                    borderRadius: '8px',
                    '&.MuiInputBase-multiline': {
                        padding: '12px',
                    },
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ownerState}) => ({
                    ...(ownerState.size !== 'small' && {
                        transform: 'translate(14px, 12px) scale(1)',
                        '&.MuiInputLabel-shrink': {
                            transform: 'translate(14px, -6px) scale(0.75)',
                        },
                    }),
                }),
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: ({ownerState}) => ({
                    ...(ownerState.size === 'medium' && {
                        padding: '0 12px',
                    }),
                }),
            },
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    height: '48px'
                }
            },
        },
    },
})

export default theme