import {FC, memo, useEffect, useRef, useState} from "react";
import {Popover} from "@mui/material";
import * as React from "react";
import {IMoreButtonProps} from "./types";

const MoreButton: FC<IMoreButtonProps> = (props) => {
    const {children, content, positionLeft, positionTop, totalElements} = props
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const ref = useRef(null)
    const open = Boolean(anchorEl)

    const element = content(open, setAnchorEl)

    useEffect(() => {
        const divEl = ref.current as HTMLDivElement | null
        // @ts-ignore
        const node = element['_owner']?.return?.stateNode as HTMLElement

        if (divEl) {
            const top = positionTop || divEl.offsetTop - (totalElements ? (totalElements - 1) * 48  : (node?.offsetHeight ? node?.offsetHeight - 68 : 0))
            const left = positionLeft || divEl.offsetLeft + 40 + 16 + 8

            setAnchorPosition({ top, left })
        }
    }, [positionTop, positionLeft])

    const [anchorPosition, setAnchorPosition] = useState({ top: positionTop || 40, left: positionLeft || 40 })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div ref={ref} onClick={handleClick}>
                {children}
            </div>


            <Popover
                open={open}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: anchorPosition.top, left: anchorPosition.left }}
                PaperProps={{
                    style:{
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    }
                }}
            >
                {element}
            </Popover>
        </>
    )
}

export default memo(MoreButton)