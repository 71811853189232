function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

interface IStringAvatar {
    (name?: string, options?: {
        width?: number
        height?: number
        fontSize?: string
        fontWeight?: string | number
    }): {
        sx: Record<string, any>,
        children: string
    }
}

export const stringAvatar: IStringAvatar = (name = 'Тест', options) => {
    const arr = name.split(' ')

    const firstLetter = arr[0] ? arr[0][0] : ''
    const secondLetter = arr[1] ? arr[1][0] : ''

    return {
        sx: {
            bgcolor: stringToColor(name),
            width: options?.width || 16,
            height: options?.height || 16,
            fontSize: options?.fontSize || '8px',
            fontWeight: options?.fontWeight || 600
        },
        children: `${firstLetter}${secondLetter}`,
    };
}