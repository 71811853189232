import React, {
    Dispatch,
    MouseEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import {
    FileInfoDTO,
    ManualTaskResponseDTO,
    PhotoTaskResponseCommentListDTO,
    TaskCheckingResultStatusDTO, TaskCheckingService
} from "../../../generated/api";
import {Alert, Box, Button, Card, Divider, Snackbar, TextField, Typography} from "@mui/material";
import MuiPopover from '@mui/material/Popover'
import Grid from "@mui/material/Grid";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import NotesIcon from '@mui/icons-material/Notes';
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import styled from "@emotion/styled";
import {LoadingButton} from "@mui/lab";

const Popover = styled(MuiPopover)({
    ['.MuiPopover-paper']: {
        width: '400px'
    },
    ['&.top-left .MuiPopover-paper']: {
        borderRadius: '0 12px 12px 12px',
    },
    ['&.top-right .MuiPopover-paper']: {
        borderRadius: '12px 0 12px 12px',
    },
    ['&.bottom-right .MuiPopover-paper']: {
        borderRadius: '12px 12px 0 12px',
    },
    ['&.bottom-left .MuiPopover-paper']: {
        borderRadius: '12px 12px 12px 0',
    }
})

interface ITaskUserManualPhotoResponseProps {
    taskResult: TaskCheckingResultStatusDTO
    loadTaskResult?: () => void
}

interface IMarkProps {
    mark: {
        id: string
        x: number
        y: number
        text: string
        corner?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
    }
    setAnchorEl: Dispatch<SetStateAction<any>>
}

interface IPhotoBoardProps {
    fileItem: FileInfoDTO
    taskResultId: number | undefined
    photoComments: Array<PhotoTaskResponseCommentListDTO> | null
    loadTaskResult?: () => void
}

interface IMarkState {
    id: string
    x: number,
    y: number,
    text: string,
    corner?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}

const Mark: React.FC<IMarkProps> = (props) => {
    const {mark, setAnchorEl} = props
    const {x, y, corner = 'bottom-left'} = mark
    const borderRadius = useMemo(() => {
        switch (corner) {
            case 'top-left':
                return '0 24px 24px 24px';

            case 'top-right':
                return '24px 0px 24px 24px';

            case 'bottom-right':
                return '24px 24px 0 24px';

            default:
                return '24px 24px 24px 0'
        }
    }, [])
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <div
                id={mark.id}
                onClick={handleClick}
                data-corner={corner}
                style={{
                    borderRadius,
                    position: 'absolute',
                    padding: '4px',
                    width: '24px',
                    height: '24px',
                    backgroundColor: 'rgba(29, 138, 254, 1)',
                    top: `${y}px`,
                    left: `${x}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
            }}>
                <NotesIcon style={{width: '16px', height: '16px', color: 'white'}} />
            </div>
        </>
    )
}

const PhotoBoard: React.FC<IPhotoBoardProps> = (props) => {
    const {fileItem, photoComments, loadTaskResult, taskResultId} = props
    const {url, fileName, id} = fileItem
    const [marks, setMarks] = useState<Array<IMarkState>>([])
    const [openMarkId, setOpenMarkId] = useState<string | null>(null)
    const [activeMark, setActiveMark] = useState<IMarkState | null>(null)
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [text, setText] = useState('')
    const [corner, setCorner] = useState('bottom-left')
    const [loading, setLoading] = useState(false)
    const [removeLoading, setRemoveLoading] = useState(false)
    const [error, setError] = useState('')
    const [imgLoaded, setImgLoaded] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const storage = localStorage.getItem('boarding_manual_photo')

    const hasIntro = useMemo(() => {
        return !!storage
    },[storage])

    const [intro, setIntro] = useState(!hasIntro)

    const open = Boolean(anchorEl)
    const comments = useMemo(() => {
        if (photoComments) {
            const photoCommentsItem = photoComments.find((el) => el.photoId === fileItem.id)

            if (photoCommentsItem) {
                return photoCommentsItem.comments || []
            }
        }

        return []
    }, [fileItem, photoComments])
    const placementY = useMemo(() => corner.split('-')[0], [corner])
    const placementX = useMemo(() => corner.split('-')[1], [corner])
    const anchorOrigin = useMemo(() => {
        return {
            vertical: placementY,
            horizontal: placementX,
        } as any
    }, [placementY, placementX])

    const getPlacement = useCallback((target: HTMLImageElement, x: number, y: number) => {
        const offsetWidth = target.offsetWidth
        const offsetHeight = target.offsetHeight

        let placementX

        let placementY

        if (offsetHeight / 2 > y) {
            placementY = 'top'
        } else {
            placementY = 'bottom'
        }

        if (offsetWidth / 2 > x) {
            placementX = 'left'
        } else {
            placementX = 'right'
        }

        return `${placementY}-${placementX}` as 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
    }, [])

    const onClick = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        const target = event.target as HTMLImageElement

        // Координаты клика относительно окна браузера
        const clickX = event.clientX;
        const clickY = event.clientY;

        // Позиция изображения относительно окна
        const rect = target.getBoundingClientRect();

        // Координаты клика относительно изображения
        let x = clickX - rect.left;
        let y = clickY - rect.top;

        const placement = getPlacement(target, x, y)

        if (placement === 'top-left') {

        } else if (placement === 'top-right') {
            x = x - 24
        } else if (placement === 'bottom-right') {
            x = x - 24
            y = y - 24
        } else {
            y = y - 24
        }

        const id = `x${x}:y${y}`

        setMarks(prevState => {
            if (prevState.some((el) => el.id === id)) {
                return prevState
            }

            return [...prevState, {
                id,
                x: Math.floor(x),
                y: Math.floor(y),
                text: '',
                corner: placement
            }]
        })

        setOpenMarkId(id)
    }, [])

    const onChange = useCallback((e: Event) => {
        const target = e.target as HTMLInputElement

        setText(target.value)
    }, [])

    const onSave = useCallback(async () => {
        setLoading(true)
        setError('')

        if (activeMark && id && taskResultId) {
            const savedCommentItem = comments?.find((el) => activeMark.id === el.id)

            if (savedCommentItem?.id) {
                await TaskCheckingService.patchAppApiEducationtaskTaskcheckingChangemanualresponsecomment({
                    id: String(taskResultId),
                    commentId: savedCommentItem.id,
                    requestBody: {
                        text: text,
                    },
                }).catch((e) => {
                    if (e.body?.message) {
                        setError(e.body.message)
                    }
                }).finally(async () => {
                    if (loadTaskResult) {
                        await loadTaskResult()
                    }

                    setActiveMark(null)
                    setLoading(false)
                    setAnchorEl(null)
                })
            } else {
                await TaskCheckingService.postAppApiEducationtaskTaskcheckingAddphotomanualresponsecomment({
                    id: String(taskResultId),
                    requestBody: {
                        photoId: id,
                        point: {
                            x: activeMark.x,
                            y: activeMark.y
                        },
                        text: text
                    }
                }).catch((e) => {
                    if (e.body?.message) {
                        setError(e.body.message)
                    }
                }).finally(async () => {
                    if (loadTaskResult) {
                        await loadTaskResult()
                    }

                    setActiveMark(null)
                    setLoading(false)
                    setAnchorEl(null)
                })
            }
        }
        setLoading(false)
    }, [activeMark, taskResultId, id, text, comments])

    const onRemove = useCallback(async () => {
        if (activeMark) {
            setRemoveLoading(true)

            const savedCommentItem = comments?.find((el) => activeMark.id === el.id)

            if (savedCommentItem?.id && taskResultId) {
                await TaskCheckingService.deleteAppApiEducationtaskTaskcheckingRemovemanualresponsecomment({
                    id: String(taskResultId),
                    commentId: savedCommentItem.id
                }).finally(async () => {
                    if (loadTaskResult) {
                        await loadTaskResult()
                    }
                })
            }
        }

        setRemoveLoading(false)

        setText('')

        setActiveMark(null)

        setMarks(prevState => prevState.filter(el => el.text))

        setAnchorEl(null)
    }, [activeMark, taskResultId, comments, setText, setActiveMark, setMarks, setAnchorEl])

    const handleClose = useCallback(() => {
        setAnchorEl(null);

        setText('')

        setActiveMark(null)

        setMarks(prevState => prevState.filter(el => el.text))
    }, [setAnchorEl, setText, setActiveMark, setMarks])

    const initComments = useCallback(() => {
        const imgHtmlElement = document.getElementById(String(id)) as HTMLImageElement | null

        if (comments && imgLoaded) {
            // @ts-ignore
            setMarks(comments.map(el => {
                let placement = null as any

                if (imgHtmlElement && typeof el.point?.x === 'number' && typeof el.point?.y === 'number') {
                    placement = getPlacement(imgHtmlElement, el.point?.x, el.point?.y)
                }

                return {
                    id: el.id,
                    text: el.text,
                    x: el.point?.x,
                    y: el.point?.y,
                    corner: placement
                }
            }))
        }
    }, [comments, imgLoaded])

    const onNextComment = useCallback(() => {
        if (marks.length > 0) {
            setActiveIndex(prevState => {
                let index = 0
                let markItem: IMarkState | null

                if (prevState >= marks.length - 1) {
                    markItem = marks[0]
                } else {
                    index = ++prevState

                    markItem = marks[index]
                }

                if (markItem) {
                    setAnchorEl(null)

                    setActiveMark(markItem)

                    const element = document.getElementById(markItem.id) as HTMLDivElement | null

                    if (element) {
                        setTimeout(() => {
                            setAnchorEl(element)
                        }, 200)
                    }
                }

                return index
            })
        }
    }, [marks])

    const onPrevComment = useCallback(() => {
        if (marks.length > 0) {
            setActiveIndex(prevState => {
                let index: number
                let markItem: IMarkState | null

                if (prevState === 0) {
                    index = marks.length - 1

                    markItem = marks[index]
                } else {
                    index = --prevState

                    markItem = marks[index]
                }

                if (markItem) {
                    setAnchorEl(null)

                    setActiveMark(markItem)

                    const element = document.getElementById(markItem.id) as HTMLDivElement | null

                    if (element) {
                        setTimeout(() => {
                            setAnchorEl(element)
                        }, 200)
                    }
                }

                return index
            })
        }
    }, [marks])

    useEffect(() => {
        initComments()
    }, [comments, imgLoaded])

    useEffect(() => {
        if (anchorEl) {
            const item = marks.find((mark) => mark.id === anchorEl.id)

            setCorner(anchorEl.dataset.corner || 'bottom-left')

            if (item) {
                setActiveMark(item)
                setText(item.text || '')
            }
        }
    }, [anchorEl])

    useEffect(() => {
        if (openMarkId) {
            const element = document.getElementById(openMarkId) as HTMLDivElement | undefined

            if (element) {
                setAnchorEl(element)
            }

            setOpenMarkId(null)
        }
    }, [openMarkId])

    useEffect(() => {
        if (!activeMark && anchorEl) {
            setAnchorEl(null)
        }
    }, [activeMark])

    return (
       <>
           <div style={{position: 'relative', display: 'inline-block'}}>
               <img
                   onLoad={() => {
                       setImgLoaded(true)
                   }}
                   id={id ? String(id): undefined}
                   onClick={onClick}
                   loading={'lazy'}
                   src={url}
                   alt={fileName}
                   style={{
                       maxWidth: '100%',
                       filter: intro ? 'blur(12px)' : undefined,
                       pointerEvents: intro ? 'none' : undefined
                   }}
               />

               {marks.map((item) => {
                   return (
                       <Mark
                           key={item.id}
                           mark={item}
                           setAnchorEl={setAnchorEl}
                       />
                   )
               })}

               {intro && (
                   <Card
                       style={{
                           width: '448px',
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -50%)',
                           borderRadius: '12px'
                       }}>
                       <Box padding={'12px'}>
                           Ответы картинками можно комментировать.
                           Просто кликните левой кнопкой мыши на картинку — и в этой точке добавится комментарий.
                       </Box>

                       <Divider />

                       <Grid padding={'12px'} justifyContent={'flex-end'} container>
                           <Grid item>
                               <Button
                                   onClick={() => {
                                       localStorage.setItem('boarding_manual_photo', 'true')

                                       setIntro(false)
                                   }}
                                   size={'small'}
                                   variant={'contained'}
                                   color={'primary'}
                               >
                                   Понятно
                               </Button>
                           </Grid>
                       </Grid>
                   </Card>
               )}

               <Popover
                   open={open}
                   anchorEl={anchorEl}
                   onClose={handleClose}
                   anchorOrigin={anchorOrigin}
                   transformOrigin={anchorOrigin}
                   className={corner}
               >
                   <Grid container padding={'10px'} justifyContent={'space-between'}>
                       <Grid item width={'62px'} display={'flex'} justifyContent={'space-between'}>
                           {marks.length > 1 && comments.some((comment) => comment.id === activeMark?.id) && (
                               <>
                                   <IconButton
                                       aria-label="prev"
                                       size="small"
                                       disabled={activeIndex === 0}
                                       onClick={onPrevComment}
                                   >
                                       <KeyboardArrowLeftIcon fontSize="inherit" />
                                   </IconButton>

                                   <IconButton
                                       aria-label="next"
                                       size="small"
                                       disabled={activeIndex === marks.length - 1}
                                       onClick={onNextComment}
                                   >
                                       <KeyboardArrowRightIcon fontSize="inherit" />
                                   </IconButton>
                               </>
                           )}
                       </Grid>

                       <Grid item>
                           <IconButton aria-label="next" size="small" onClick={handleClose}>
                               <CloseIcon fontSize="inherit" />
                           </IconButton>
                       </Grid>
                   </Grid>

                   <Divider />

                   <Grid container padding={'12px'}>
                       <Grid item width={'100%'}>
                           <TextField label={'Комментарий'} fullWidth value={text} onChange={onChange as any}/>
                       </Grid>
                   </Grid>

                   <Divider />

                   <Grid container padding={'12px'} justifyContent={'flex-end'}>
                       <Grid item>
                           <LoadingButton
                               loading={removeLoading}
                               color={'error'}
                               size={'small'}
                               onClick={onRemove}
                           >
                               Удалить
                           </LoadingButton>
                       </Grid>

                       <Grid item>
                           <LoadingButton
                               disabled={!text}
                               loading={loading}
                               size={'small'}
                               color={'primary'}
                               variant={'contained'}
                               onClick={onSave}
                           >
                               Сохранить
                           </LoadingButton>
                       </Grid>
                   </Grid>
               </Popover>
           </div>

           <Snackbar
               open={!!error}
               autoHideDuration={6000}
               onClose={() => {
                   setError('')
               }}
           >
               <Alert
                   severity="error"
                   variant="filled"
                   sx={{ width: '100%' }}
                   onClose={() => {
                       setError('')
                   }}
               >
                   {error}
               </Alert>
           </Snackbar>
       </>
    )
}

const TaskUserManualPhotoResponse: React.FC<ITaskUserManualPhotoResponseProps> = (props) => {
    const {taskResult, loadTaskResult} = props

    const lastResponse = useMemo(() => {
        return taskResult?.lastResponse as ManualTaskResponseDTO
    }, [taskResult])

    const photoComments = useMemo(() => {
        return lastResponse?.photoComments || null
    }, [lastResponse])

    const photoFiles = useMemo(() => {
        return lastResponse?.photoFiles
    }, [lastResponse])

    const isPhoto = useCallback((mimeType: string) => {
        const el = mimeType.split('/')[0]

        return el ? el.includes('image') : false
    }, [])

    return (
        <div>
            {photoFiles?.map((item) => {
                return (
                    <div key={item.id}>
                        {isPhoto(item.mimeType || '') ? (
                            <div>
                                <div>
                                    <PhotoBoard
                                        fileItem={item}
                                        taskResultId={taskResult.id}
                                        photoComments={photoComments}
                                        loadTaskResult={loadTaskResult}
                                    />
                                </div>

                                <div>
                                    <Button
                                        style={{padding: '4px 0'}}
                                        size={'small'}
                                        href={item.url}
                                        endIcon={<SaveAltIcon sx={{width: '24px'}}/>}
                                    >
                                        {item.fileName}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <Grid container>
                                <Grid item marginRight={'8px'}>
                                    <Typography fontWeight={300}>
                                        Ответ по ссылке:
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <a href={item.url} target={'_blank'}>
                                        {item.fileName}
                                    </a>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default TaskUserManualPhotoResponse