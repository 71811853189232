export function scrollToElementCenterWithinParent(child: HTMLElement, parent: HTMLElement): void {
    const childRect = child.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    // Позиция элемента относительно верхней границы родителя
    const childTopRelativeToParent = childRect.top - parentRect.top;

    // Высота родителя для расчета центра
    const parentHeight = parent.clientHeight;

    // Вычисляем позицию, к которой нужно прокрутить
    const scrollToPosition = parent.scrollTop + childTopRelativeToParent - (parentHeight / 2) + (childRect.height / 2);

    // Прокручиваем родительский элемент
    parent.scrollTo({
        top: scrollToPosition,
        behavior: 'smooth' // Плавный скролл
    });
}
