import Box from "@mui/material/Box";
import * as React from "react";
import {Divider, Grid, ListItemIcon, ListItemText, MenuItem, useMediaQuery} from "@mui/material";
import {Link, Outlet} from "react-router-dom";
import {FC, memo, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {ReactComponent as IconUcheba} from './assets/logo-ucheba-vector.svg'
import {ReactComponent as IconUchebaFull} from './assets/logo-ucheba-vector_full.svg'
import NavItem from "../../components/custom/NavItem";
import styles from './styles.module.css'
import Navigation from "../../components/custom/Navigation";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MoreButton from "../../components/custom/MoreButton";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import LiveHelpIcon from "@mui/icons-material/LiveHelpOutlined";
import {SubjectDTO} from "../../generated/api";
import {ProfileContext} from "../../App";
import {logout} from "../../helpers/logout";

const Layout: FC = () => {
    const [isFull, setIsFull] = useState(false)
    const refSidebar = useRef<HTMLElement | null>(null)
    const [morePositionLeft, setMorePositionLeft] = useState<number | undefined>(undefined)
    const [selectedSubject, setSelectedSubject] = useState<SubjectDTO>();
    const changeSubjectHandler = useCallback((subject: SubjectDTO) => {
        setSelectedSubject(subject);
        localStorage.setItem('selectedSubjectId', subject.id?.toString() || '');
    }, [setSelectedSubject]);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = useCallback(() => {
        setMobileOpen(!mobileOpen);
    }, [setMobileOpen, mobileOpen])

    useEffect(() => {
        if (refSidebar.current) {
            const left = refSidebar.current?.offsetWidth

            if (left) {
                setMorePositionLeft(left + 32)
            }
        }
    }, [])

    const onHandleResize = useCallback(() => {
        if (window.innerWidth >= 1366) {
            setIsFull(true)
        } else {
            setIsFull(false)
        }
    },[])

    useLayoutEffect(() => {

        onHandleResize()
        window.addEventListener('resize', onHandleResize)

        return () => {
            window.removeEventListener('resize', onHandleResize)
        }
    }, [])

    const profile = useContext(ProfileContext)

    return (
        <Grid container sx={{height: '100vh', minHeight: '100vh'}}>
            <Grid item xs={'auto'} position={'relative'}>
                <Box padding={'24px 0 24px 24px'} height={'100%'} position={'sticky'} top={0}>
                    <Box
                        ref={refSidebar}
                        className={styles.Layout__block__sidebar}
                        borderRadius={'12px'}
                        bgcolor={'#263340'}
                        color={'white'}
                        padding={'8px'}
                        height={'100%'}
                    >
                        <Grid container rowSpacing={1} height={'100%'}>
                            <Grid item xs={12}>
                                <Box padding={'8px'} width={'100%'}>
                                    {!isFull && <NavItem IconElement={IconUcheba} href={'/'} />}

                                    {isFull && (
                                        <div style={{
                                            padding: '8px 0'
                                        }}>
                                            <Link to={'/'}>
                                                <IconUchebaFull />
                                            </Link>
                                        </div>
                                    )}
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box padding={'8px'} width={'100%'}>
                                    <Navigation isFull={isFull} />
                                </Box>
                            </Grid>

                            <Grid item xs={12} display={'flex'} alignItems={'flex-end'}>
                                <Box padding={'8px'} width={'100%'}>
                                    <NavItem Icon={<LiveHelpIcon sx={{color: 'white'}} />} text={isFull ? 'Поддержка' : undefined} />

                                    <MoreButton
                                        positionLeft={morePositionLeft}
                                        content={() => {
                                            return (
                                                <Paper
                                                    style={{
                                                        padding: '0',
                                                        width: '161px',
                                                        backgroundColor: '#263340',
                                                        borderRadius: '16px',
                                                }}>
                                                    <Box padding={'20px 20px 0 20px'}>
                                                        <Typography color={'white'} fontSize={'14px'} lineHeight={'21px'} fontWeight={700} style={{
                                                            marginBottom: '16px'
                                                        }}>
                                                            {profile?.name || ''}
                                                        </Typography>

                                                        {/*<Typography color={'rgba(255, 255, 255, 0.56)'} fontSize={'14px'} lineHeight={'21px'}>*/}
                                                        {/*    {profile}*/}
                                                        {/*</Typography>*/}
                                                    </Box>

                                                    <Divider sx={{backgroundColor: 'rgba(255, 255, 255, 0.08)'}} />

                                                    <Box>
                                                        <MenuList style={{padding: 0}}>
                                                            <MenuItem
                                                                style={{
                                                                    height: '48px'
                                                                }}
                                                            >
                                                                <ListItemIcon color={'white'}>
                                                                    <SettingsIcon sx={{color: 'white'}} />
                                                                </ListItemIcon>

                                                                <ListItemText style={{color: 'white'}}>
                                                                    <NavItem text="Настройки" href={'/settings'} />
                                                                </ListItemText>
                                                            </MenuItem>

                                                            <MenuItem
                                                                style={{
                                                                    height: '48px'
                                                                }}
                                                                onClick={() => {
                                                                    logout()
                                                                }}
                                                            >
                                                                <ListItemIcon color={'white'}>
                                                                    <LogoutIcon sx={{color: 'white'}} />
                                                                </ListItemIcon>

                                                                <ListItemText style={{color: 'white'}}>
                                                                    Выход
                                                                </ListItemText>
                                                            </MenuItem>
                                                        </MenuList>
                                                    </Box>
                                                </Paper>
                                            )
                                        }}>

                                        <NavItem withAvatar avatarUrl={profile?.avatar} text={isFull ? profile?.name || '' : undefined} />
                                    </MoreButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>

            <Grid sx={{height: '100%'}} item padding={'24px'} minHeight={'100%'} xs zeroMinWidth>
                <Outlet />
            </Grid>
        </Grid>
    )
}

export default memo(Layout)