import React, {useMemo} from "react";
import Grid from "@mui/material/Grid";
import {Stack, Typography} from "@mui/material";
import {
    TaskCheckingResultStatusDTO,
    TaskResultEnum,
    TextEmployeeTaskDTO,
    TextTaskResponseDTO
} from "../../../generated/api";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface ITaskUserTextResponseProps {
    taskResult: TaskCheckingResultStatusDTO
}

const TaskUserTextResponse: React.FC<ITaskUserTextResponseProps> = (props) => {
    const {taskResult} = props

    const lastResponse = useMemo(() => {
        return taskResult?.lastResponse as TextTaskResponseDTO
    }, [])

    const correctAnswer = useMemo(() => {
        const task = taskResult?.task as TextEmployeeTaskDTO

        return task?.correctAnswer
    }, [taskResult])

    const response = useMemo(() => {
        return lastResponse?.response
    }, [lastResponse])

    const result = useMemo(() => {
        if (taskResult.result === TaskResultEnum.CORRECT) {
            return (
                <Grid container>
                    <Grid item marginRight={'2px'} alignItems={'center'} display={'flex'}>
                        <CheckIcon sx={{color: 'rgba(64, 187, 69, 1)', width: '24px'}}/>
                    </Grid>

                    <Grid item alignItems={'center'} display={'flex'}>
                        <Typography fontSize={'14px'} lineHeight={'21px'} sx={{color: 'rgba(64, 187, 69, 1)'}}>
                            {response}
                        </Typography>
                    </Grid>
                </Grid>
            )
        }

        if (taskResult.result === TaskResultEnum.INCORRECT) {
            return (
                <Grid container>
                    <Grid item marginRight={'2px'} alignItems={'center'} display={'flex'}>
                        <CloseIcon sx={{color: 'rgba(255, 8, 41, 1)', width: '24px'}}/>
                    </Grid>

                    <Grid item alignItems={'center'} display={'flex'}>
                        <Typography fontSize={'14px'} lineHeight={'21px'} sx={{color: 'rgba(255, 8, 41, 1)'}}>
                            {response}
                        </Typography>
                    </Grid>
                </Grid>
            )
        }

        if (taskResult.result === TaskResultEnum.SKIPPED) {
            return (
                <Grid container>
                    <Grid item marginRight={'2px'} alignItems={'center'} display={'flex'}>
                        <CloseIcon sx={{color: 'rgba(255, 8, 41, 1)', width: '24px'}}/>
                    </Grid>

                    <Grid item alignItems={'center'} display={'flex'}>
                        <Typography fontSize={'14px'} lineHeight={'21px'} sx={{color: 'rgba(255, 8, 41, 1)'}}>
                            Нет ответа
                        </Typography>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container>
                <Grid item alignItems={'center'} display={'flex'}>
                    <Typography fontSize={'14px'} lineHeight={'21px'} sx={{color: 'rgba(0,0,0,0.2)'}}>
                        Нет ответа
                    </Typography>
                </Grid>
            </Grid>
        )
    }, [taskResult])

    return (
        <div>
            <Grid container>
                <Grid item marginRight={'20px'}>
                    <Typography fontSize={'14px'} lineHeight={'21px'} fontWeight={500}>
                        Ответ ученика
                    </Typography>

                    <Stack padding={'10px 0'}>
                        {result}
                    </Stack>
                </Grid>

                <Grid item>
                    <Typography fontSize={'14px'} lineHeight={'21px'} fontWeight={500}>
                        Правильный ответ
                    </Typography>

                    <Stack padding={'10px 0'}>
                        <Grid container minHeight={'24px'}>
                            <Grid item alignItems={'center'} display={'flex'}>
                                <Typography
                                    fontSize={'14px'}
                                    lineHeight={'21px'}
                                    sx={{color: 'rgba(64, 187, 69, 1)'}}
                                >
                                    {correctAnswer}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </div>
    )
}

export default TaskUserTextResponse