/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProgramDTO } from '../models/ProgramDTO';
import type { ProgramForSelectDTO } from '../models/ProgramForSelectDTO';
import type { ProgramGetListResponse } from '../models/ProgramGetListResponse';
import type { ProgramItemTypeEnum } from '../models/ProgramItemTypeEnum';
import type { ProgramSaveRequest } from '../models/ProgramSaveRequest';
import type { YandexFeedProgramDTO } from '../models/YandexFeedProgramDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProgramService {

    /**
     * Получение списка программ.
     * @returns ProgramGetListResponse Список Program
     * @throws ApiError
     */
    public static getAppApiCalendarProgramcrudGetlist({
        limit,
        offset,
        subjectIdsArray,
    }: {
        limit?: number,
        offset?: number,
        subjectIdsArray?: Array<number>,
    }): CancelablePromise<ProgramGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs',
            query: {
                'limit': limit,
                'offset': offset,
                'subjectIds[]': subjectIdsArray,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Создание программы.
     * @returns ProgramSaveRequest Созданная программа
     * @throws ApiError
     */
    public static postAppApiCalendarProgramcrudActioncreate({
        requestBody,
    }: {
        /**
         * Данные программы
         */
        requestBody?: ProgramSaveRequest,
    }): CancelablePromise<ProgramSaveRequest> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/programs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение списка программ для выбора
     * @returns ProgramForSelectDTO Список Program
     * @throws ApiError
     */
    public static getAppApiCalendarProgramcrudGetlistforselect({
        idsArray,
        excludeIdsArray,
        query,
        subjectIdsArray,
        excludeDefault,
        onlyActual,
    }: {
        idsArray?: Array<number>,
        excludeIdsArray?: Array<number>,
        query?: string | null,
        subjectIdsArray?: Array<number>,
        excludeDefault?: boolean | null,
        onlyActual?: boolean | null,
    }): CancelablePromise<Array<ProgramForSelectDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs/for-select',
            query: {
                'ids[]': idsArray,
                'excludeIds[]': excludeIdsArray,
                'query': query,
                'subjectIds[]': subjectIdsArray,
                'excludeDefault': excludeDefault,
                'onlyActual': onlyActual,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * Получение программы по идентификатору.
     * @returns ProgramDTO Созданная программа
     * @throws ApiError
     */
    public static getAppApiCalendarProgramcrudGetbyid({
        id,
    }: {
        /**
         * Идентификатор программы
         */
        id: number,
    }): CancelablePromise<ProgramDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Программа с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Удаление программы.
     * @returns void
     * @throws ApiError
     */
    public static deleteAppApiCalendarProgramcrudActiondelete({
        id,
    }: {
        /**
         * Идентификатор программы
         */
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/programs/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Программа с таким идентификатором не найдена`,
            },
        });
    }

    /**
     * Обновление программы.
     * Для обновления не обязательно указывать все поля, достаточно указать только те, которые нужно обновить
     * @returns void
     * @throws ApiError
     */
    public static patchAppApiCalendarProgramcrudActionupdate({
        id,
        requestBody,
    }: {
        /**
         * Идентификатор Program
         */
        id: number,
        /**
         * Program
         */
        requestBody?: ProgramSaveRequest,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/programs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Ошибка валидации`,
                401: `Необходима авторизация`,
                403: `Нет доступа`,
                404: `Program с таким идентификатором не найден`,
            },
        });
    }

    /**
     * Получение списка программ по её элементу
     * @returns ProgramGetListResponse Список Program
     * @throws ApiError
     */
    public static getAppApiCalendarProgramcrudGetlistbyitem({
        limit,
        offset,
        subjectId,
        programItemId,
        programItemType,
        query,
    }: {
        limit?: number,
        offset?: number,
        subjectId?: number | null,
        programItemId?: number | null,
        programItemType?: ProgramItemTypeEnum | null,
        query?: string | null,
    }): CancelablePromise<ProgramGetListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/programs/item',
            query: {
                'limit': limit,
                'offset': offset,
                'subjectId': subjectId,
                'programItemId': programItemId,
                'programItemType': programItemType,
                'query': query,
            },
            errors: {
                401: `Необходима авторизация`,
                403: `Нет доступа`,
            },
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static getAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static putAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static postAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static deleteAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static optionsAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'OPTIONS',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static headAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'HEAD',
            url: '/v1/private/programs/yandex-feed',
        });
    }

    /**
     * @returns YandexFeedProgramDTO Список Program
     * @throws ApiError
     */
    public static patchAppApiCalendarYandexfeedActiongetprograms(): CancelablePromise<Array<YandexFeedProgramDTO>> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/private/programs/yandex-feed',
        });
    }

}
