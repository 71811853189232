import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from "@mui/material";
import React, {useCallback} from "react";
import FileUploader from "../wrappers/FileUploader";
import {FileInfoDTO, FilesService} from "../../generated/api";

interface FileUploaderDialogProps {
  open: boolean,
  onClose: () => void,
  onInsert: (file: FileInfoDTO) => void,
}

export default function FileUploaderDialog(props: FileUploaderDialogProps)
{
  const {
    open,
    onClose,
    onInsert
  } = props

  const [fileId, setFileId] = React.useState<number|null>(null)
  const onFileChange = useCallback((value: number[]) => {
    setFileId(value.length ? value[0] : null)
  }, [setFileId])
  const onInsertClick = useCallback(() => {
    if (!fileId) {
      return
    }
    FilesService.getAppApiFilesuploaderUploaderGetbyids({
      ids: [fileId]
    }).then((response) => {
      const file = response[0]
      onInsert(file)
    })
  }, [fileId, onInsert])
  return (
      <Dialog fullWidth open={open} maxWidth='xs'>
        <DialogTitle>{"Загрузка файлов"}</DialogTitle>
        <DialogContent>
          <FileUploader
              value={fileId ? [fileId] : []}
              types={['audio/*', 'application/pdf']}
              onChange={onFileChange}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Можно загрузить только аудио или pdf файлы
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Закрыть</Button>
          <Button disabled={!fileId} onClick={onInsertClick}>Вставить</Button>
        </DialogActions>
      </Dialog>
  )
}